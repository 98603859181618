import axios from 'axios';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import autharizedHeaders from './lib/autharizedHeaders';
import uuid from 'uuid';
import localStorageSafe from '../lib/localStorageSafe';

const uploadImagesAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/uploadImages' });

const policyAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/simpleText' });

const contactAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/customerServiceContact' });

const uploadRoRoImageAPI = axios.create({ baseURL: 'https://dev.file.roro3.com/roro-file-server/api/fileUpload/uploadMultipleTempImages'});

export const postContact = async ( params ) => {
    return await contactAPI.post('/', {customerFeedback:{email:params.email,phone:params.phone,name:params.fullName,description:params.description,type:'feedback',uuid:uuidv4()}})
};

export const postReport = async ( payload ) => {
    return await contactAPI.post('/', {customerServiceContact:{...payload,uuid:uuidv4()}},{headers:autharizedHeaders()}).then(res=>res.data.customerServiceContact)
};

export const getPolicy = async (title,language) => {
    return await policyAPI.get(`/${title}?language=${language}`)
}

export const uploadImages = async ({ imageFiles = [], uploadParams = {withoutWaterMark:false }}) => {
	const formData = new FormData();
	console.log(imageFiles,uploadParams)
	imageFiles.forEach((file) => {
		formData.append('images', file);
        formData.append('tagsList', '#image');
    });
    const paramObj = _.pickBy(uploadParams,_.identity);
	const paramKeys = _.keys(paramObj)
	_.values(paramObj).map((paramValue,index)=>{
		return formData.append(paramKeys[index],paramValue)
	})
    // formData.append('withoutWaterMark',withoutWaterMark);
	return await uploadImagesAPI.post('/', formData, { headers: { ...autharizedHeaders(), 'Content-Type': 'multipart/mixed' } }).then(res => res.data.images);
};

export const uploadTempRoroImages = async ({ imageFiles = [] }) => {
	const formData = new FormData();
	console.log(imageFiles)
	imageFiles.forEach(({ file, tags }) => {
		formData.append('images', file);
        formData.append('tagsList', '#image');
    });
    // const paramObj = _.pickBy(uploadParams,_.identity);
	// const paramKeys = _.keys(paramObj)
	// _.values(paramObj).map((paramValue,index)=>{
	// 	return formData.append(paramKeys[index],paramValue)
	// })
	formData.append('tempType','#oldRoroArticle');
	formData.append('lastDays','0');
	return await uploadRoRoImageAPI.post('/', formData).then(res => res.data.images);
};
export const autoCompleteAddressAPI = query => {
	const lngLat = _.isEmpty(localStorageSafe.getItem('gps', 'object')) ? [ -123.145575, 49.2695179 ] : localStorageSafe.getItem('gps', 'object').coordinates
	return axios
		.get(`${process.env.REACT_APP_ADDRESS_API}/autocomplete/json?input=${query.value}&sessionToken=${query.sessionToken}&roroClient=lasfu${lngLat[0]?`&lng=${lngLat[0]}`:''}${lngLat[1]?`&lat=${lngLat[1]}`:''}&radius=200000`)
		.then(resp => ({features: resp.data.geometries}))
		.catch(err => ({ features: [] }));
};
export const getAddressAPI = ({ placeId,sessionToken }) => {
	return axios
		.get(`${process.env.REACT_APP_ADDRESS_API}/detail/json?placeId=${placeId}&sessionToken=${sessionToken}`)
		.then(res => {
			return res.data.geometry;
		})
		.catch(() => ({
			geometry: {
				formattedAddress:'',
				latLng: {}
			}
		}));
};