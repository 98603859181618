import _ from 'lodash';
export const stringfyURI = value => encodeURIComponent(JSON.stringify(value));

const passJsonParse = value => {
	try {
		return (value = JSON.parse(value));
	} catch (err) {
		return value;
	}
};
const splitArray = value => {
	let values = _.split(value, ',');
	if (values.length > 1) {
		values = _.map(values, v => passJsonParse(v));
		return values;
	} else {
		return value;
	}
};

const parseQuery = {
	parse: search => {
		// console.log(search[0]);
		if (search[0] !== '?') {
			return {};
		}
		let str = search.substr(1);
		if (str[str.length - 1] === '&') {
			str = str.slice(0, str.length - 1);
		}
		try {
			return JSON.parse('{"' + str.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function(key, value){
				if (key !== '') {
					value = decodeURIComponent(value);
					value = passJsonParse(value);
					value = splitArray(value);
					value = passJsonParse(value);
				}

				return value;
			});
		} catch (err) {
			return {};
		}
	},
	parseObjToQueryStr: (query = {}) => {
		const keys = Object.keys(query);
		let str = '';
		keys.forEach(key => {
			str = str + key + '=' + query[key] + '&';
		});
		str = str.slice(0, str.length - 1);
		return str;
	},
	stringfyURI
};
JSON.stringfyURI = stringfyURI;
export default parseQuery;
