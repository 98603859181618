/*
 * @Author: xin wang
 * @Date: 2020-12-08 10:36:29
 * @LastEditors: xinwang
 * @LastEditTime: 2021-05-26 15:53:44
 * @FilePath: \lasfu2.0\client\src\routes\routes.js
 */
import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { homeRoute, articleRoute, searchRoute, authRoute, conditionRoute, transactionRoute, adminRoute, postRoute, managementRoute, conversationRoute, downloadRoute, helpRoute, qrRoute,restaurantRoute } from '../map/map';
import RouteBasic from './RouteBasic';
import RouteUser from './RouteUser';
import RouteAdmin from './RouteAdmin';

const AppPage = React.lazy(() => import('../components/Pages/AppPage/App'));

const HomePage = React.lazy(() => import('../components/Pages/HomePage/HomePage'));
const ArticlePage = React.lazy(()=> import('../components/Pages/ArticlePage/ArticlePage'));
const TestPage = React.lazy(()=> import('../components/Pages/TestPage/ArticlePage'));
// const ArticleDetailPage = React.lazy(() => import('../components/Pages/ArticleDetailPage/ArticleDetailPage'));
// const ArticleListPage = React.lazy(() => import('../components/Pages/ArticleListPage/ArticleListPage'));
const SearchMainPage = React.lazy(() => import('../components/Pages/SearchMainPage/SearchMainPage'));
const AuthPage = React.lazy(() => import('../components/Pages/AuthPage/AuthPage'));
const AdminPage = React.lazy(() => import('../components/Pages/AdminPage/AdminPage'));
const UserPage = React.lazy(() => import('../components/Pages/UserPage/UserPage'));
// const ConditionPage = React.lazy(() => import('../components/Pages/ConditionPage/ConditionPage'));
const TransactionPage = React.lazy(() => import('../components/Pages/TransactionPage/TransactionPage'));
const ManagementPage = React.lazy(() => import('../components/Pages/ManagementPage/ManagementPage'));
const ConversationPage = React.lazy(() => import('../components/Pages/ConversationPage/ConversationPage'));
const PostPage = React.lazy(() => import('../components/Pages/PostPage/PostPage'));
const AboutPage = React.lazy(() => import('../components/Pages/AboutPage/AboutPage'));
const DownloadPage = React.lazy(() => import('../components/Pages/DownloadPage/DownloadPage'));
const HelpPage = React.lazy(() => import('../components/Pages/HelpPage/HelpPage'));
const QRCodePage = React.lazy(() => import('../components/Pages/QRCodePage/QRCodePage'));
const RestaurantPage = React.lazy(()=>import('../components/Pages/RestaurantPage/RestaurantPage'));

const Routes = () => {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				{/* <Redirect exact from='/' to={homeRoute.main.url} /> */} 
				<Redirect exact from='/' to={postRoute.main.url} />

				 {window.innerWidth > 769 && <Redirect exact from={managementRoute.main.url} to={managementRoute.profile.url} />} 
				<RouteBasic path={`${searchRoute.main.url}`} component={SearchMainPage} />
			    {/* <RouteUser exact {...managementRoute.user} component={UserPage} />  */}
				<RouteBasic exact {...managementRoute.main} component={ManagementPage} />
				<RouteBasic exact {...postRoute.main} component={PostPage} />
				<RouteBasic exact {...restaurantRoute.main} component={RestaurantPage}/>
				<RouteBasic exact {...conversationRoute.main} component={ConversationPage} />
				<RouteBasic exact {...transactionRoute.main} component={TransactionPage} />
				 {/* <RouteBasic exact {...articleRoute.detail} component={ArticleDetailPage} />  */}
				<RouteBasic exact {...articleRoute.main} component={TestPage} />
				<RouteBasic exact {...helpRoute.main} component={HelpPage} />
				<RouteBasic exact path='/App/:category?' component={AppPage} />
				<RouteBasic exact {...authRoute.main} component={AuthPage} />
				<RouteAdmin path={`${adminRoute.main.url}/:category?/:target?`} component={AdminPage} />
			    {/* <RouteBasic path={`${conditionRoute.main.url}/:routeName?/:name?`} component={ConditionPage} />   */}
				<RouteBasic exact path={homeRoute.main.url} component={HomePage} />
				<RouteBasic exact {...qrRoute.main} component={QRCodePage} />
				<Route path={downloadRoute.main.url + '/:target?'} component={DownloadPage} />
			    <Route path={'/About/:section?'} component={AboutPage} />
				<Route exact path=' ' component={HomePage} />
			</Switch>
		</Suspense>
	);
};
export default Routes;
