import React from 'react';
import ReactDOM from 'react-dom';

//import 'semantic-ui-css/semantic.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './hover.css';
import './index.css';
import './fontStyle.css';
import './animation.css';
import './index.scss';
import faker from 'faker';
// import App from './App';
import { GlobalProvider } from './contexts/Global/GlobalContext';
import { Provider as AuthProvider } from './contexts/Global/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/routes';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

// Following code is required to active google analytics service
ReactGA.initialize('UA-113458829-2');
ReactGA.ga('require', 'GTM-MGKJ7NJ');

sessionStorage.setItem('userUUID', faker.random.uuid());
var _lsTotal = 0,
	_xLen,
	_x;
for (_x in localStorage) {
	if (!localStorage.hasOwnProperty(_x)) {
		continue;
	}
	_xLen = (localStorage[_x].length + _x.length) * 2;
	_lsTotal += _xLen;
	console.log(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB');
}
console.log('Total = ' + (_lsTotal / 1024).toFixed(2) + ' KB');
function hasTouch(){
	return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

if (hasTouch()) {
	// remove all :hover stylesheets
	try {
		// prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match(':hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) {}
}
// In the following line, you should include the prefixes of implementations you want to test.
// window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
// DON'T use "var indexedDB = ..." if you're not in a function.
// Moreover, you may need references to some window.IDB* objects:
// window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
// window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
// (Mozilla has never prefixed these objects, so we don't need window.mozIDB*)

// console.log(db);

// var objectStore = db.transaction('toDoList', 'readwrite').objectStore('toDoList');

ReactDOM.render(
	<BrowserRouter>
		<GlobalProvider>
			<AuthProvider>
				<Routes />
			</AuthProvider>
		</GlobalProvider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
