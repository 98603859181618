import React,{createContext, useReducer, useEffect, useCallback, useContext,useMemo} from 'react';
import axios from 'axios';
import getLanguage from '../../API/lib/getLanguage';
import localStorageSafe from '../../lib/localStorageSafe';
import GlobalContext from './GlobalContext';
import {authRoute,postRoute,transactionRoute,managementRoute,adminRoute} from '../../map/map';
import parseQuery from '../../lib/parseQuery';
import _ from 'lodash';
import ReactGA from 'react-ga';

import { 
    checkavailabilityAPI,
	signUpPhoneAPI,
	loginWechatAPI,
	loginAPI,
	getProfileAPI,
    sendCodeAPI,
    postProfileAPI,
    postProfileImageAPI,
    userinfoPasswordChangeAPI,
    userinfoPasswordResetAPI,
    userinfoPasswordResetRequestByphoneAPI,
    postVerificationFiles,
    getHasNewMessage,
    userinfoBindWechatAPI,
    userinfoBindPhoneAPI,
    postHousingRequest
 } from '../../API/auth.api';
import { dispatchAction,dispatchLocalAction } from '../lib/index';

const Context = createContext({});

const initAuthState = {
    auth:{},
    user:{},
    accessToken:null,
    refreshToken:null,
    ...localStorageSafe.getItem('authState','object'),
    condition:{isLoading:false,error:{},isSuccess:false}
};

const authReducer = (state={}, action={}) => {
    const {actionType,condition={}} = action;
    const {error={}} = condition;
    const payload = action.payload || {};
    switch(actionType){
        case 'start':
            return{
                ...state,
                ...action
            };
        case 'fail':
            if (error.title) {
				window.pushError({ title: error.title, message: getLanguage() === 'zh' ? error.cnErrorMessage : error.enErrorMessage });
			}
			return {
				...state,
				...action
            };
        case 'checkAuth':
        case 'getHasNewMessage':
            return { ...state, ...action, ...payload };
        case 'getAuth':
        case 'getLogin':
        case 'getUser':
        case 'bindWechat':
        case 'bindPhone':
            localStorageSafe.setItem('authState', { ...state, actionType, ...payload });
            return { ...state, ...action, ...payload };
        case 'updatedPW':
            localStorageSafe.setItem('authState', { ...state, actionType, auth: { ...state.auth, passwordSet: true } });
            return { ...state, ...action, auth: { ...state.auth, passwordSet: true } };
        case 'getCode':
            return { ...state, ...action, code: payload.code };
        case 'clearAuth':
            localStorageSafe.setItem('prevAuthState',{...localStorageSafe.getItem('authState', 'object')});
            localStorage.removeItem('authState');
            return {    
                auth:{},
                user:{},
                accessToken:null,
                refreshToken:null,
                condition:{isLoading:false,error:{},isSuccess:false}
            };
        default:
            return { ...state };
    }
};

const resultHandler = ({actionType,payload}) => {
    switch (actionType){
		case 'checkAccount':
			if (payload.auth.userRegistered === true) {
                if(payload.auth.passwordSet === true){
                    window.pushG(`${authRoute.loginPhone.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
                }else{
                    window.pushG(`${authRoute.loginPhoneCode.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
                }
			} else if (payload.auth.userRegistered === false) {
				window.pushG(`${authRoute.signUpPhone.url}?countryCode=${payload.auth.countryCode}&phoneNumber=${payload.auth.phoneNumber}`);
			}
            break;
		case 'updateProfile':
		case 'updateProfileImage':
			payload.user && window.alertG('Update Success',{icon:'ui icon check'})
            break;
        case 'fastLogin':
		case 'signUpPhone':
		case 'loginPhone':
		case 'loginWechat':
		case 'resetPasswordByCode':
		case 'updateProfileAddressLocation':
            localStorage.setItem('loginDate', new Date());
            console.log(payload)
            if(payload.auth && _.find(payload.auth.roles, role => role.name === 'ROLE_ADMIN')){
                window.pushG(adminRoute.main.to);
            }else{
                window.pushG(postRoute.main.to);
            }
			break;
		case 'updatePassword':
            if(payload.passwordChanged){
                ReactGA.event({
                    category:'Auth',
                    action:'Update password'
                })
                window.alertG('Update Success',{icon:'ui icon check'})
            }
                break;
		case 'resetPasswordByPhoneVerificationCode':
			window.pushG(authRoute.resetPassword.url + '?' + parseQuery.parseObjToQueryStr({ code:payload.code }))
            break;
        case 'bindWechat':
        case 'bindPhone':
            payload && payload.auth && window.alertG('Update Success',{icon:'ui icon check'})
            window.pushG(managementRoute.bind.url)
            break;
		case 'postHousingRequest':
            window.alertG('Update Success',{icon:'ui icon check'})
            break;
        default:
            break;
    }
}
export const Provider = props => {
    const {t} = useContext(GlobalContext);
    const [ state, dispatch ] = useReducer(authReducer, initAuthState);
    const {accessToken} = state;
    const checkAccountAction = useCallback(dispatchAction(dispatch, checkavailabilityAPI,'checkAuth'));
    const signUpPhoneAction = useCallback(dispatchAction(dispatch,signUpPhoneAPI,'getAuth'));
    const loginWechatAction = useCallback(dispatchAction(dispatch,loginWechatAPI,'getAuth'));
    const bindWechatAction = useCallback(dispatchAction(dispatch,userinfoBindWechatAPI,'bindWechat'));
    const bindPhoneAction = useCallback(dispatchAction(dispatch,userinfoBindPhoneAPI,'bindPhone'))
    const loginAction = useCallback(dispatchAction(dispatch,loginAPI,'getLogin','Login fail'));
    const getProfileAction = useCallback(dispatchAction(dispatch,getProfileAPI,'getUser'));
    const updateProfileAction = useCallback(dispatchAction(dispatch,postProfileAPI,'getUser'));
    const updateProfileImageAction = useCallback(dispatchAction(dispatch,postProfileImageAPI,'getUser'));
    const updatePasswordAction = useCallback(dispatchAction(dispatch,userinfoPasswordChangeAPI,'success'));
    const sendPhoneVerifyCodeAction = useCallback(dispatchAction(dispatch,sendCodeAPI,'success'));
    const resetPasswordByCodeAction = useCallback(dispatchAction(dispatch,userinfoPasswordResetAPI,'getAuth'));
    const findPasswordAction = useCallback(dispatchAction(dispatch,userinfoPasswordResetRequestByphoneAPI,'success'));
    const postVerificationFilesAction = useCallback(dispatchAction(dispatch,postVerificationFiles,'success'));
    const logoutAction = useCallback(dispatchLocalAction(dispatch,'clearAuth'));
    const getHasNewMessageAction = useCallback(dispatchAction(dispatch,getHasNewMessage,'getHasNewMessage'));
    const postHousingRequestAction = useCallback(dispatchAction(dispatch,postHousingRequest,'success'));
    useEffect(()=>{
		if (!localStorage.getItem('loginDate') || (new Date(localStorage.getItem('loginDate')) < new Date('2020-03-20'))) {
			localStorage.clear();
		}
    })
    //
    const _onChange = useCallback((e,data={}) => {
        const { actionType, files, code, countryCode, verificationCode, phoneNumber, password, user={}, oldPassword, newPassword } = data;
        switch(actionType){
            case 'checkAccount':
                checkAccountAction({countryCode,phoneNumber}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'signUpPhone':
                signUpPhoneAction({countryCode,phoneNumber,verificationCode}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'loginPhone':
                loginAction({countryCode,phoneNumber,password,verificationCode}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'loginWechat':
                loginWechatAction({code}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'bindWechat':
                bindWechatAction({code}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'bindPhone':
                bindPhoneAction({countryCode,phoneNumber,verificationCode}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'getProfile':
                getProfileAction().then(payload=>{
                    console.log(payload)
                    resultHandler({actionType,payload})
                });
                break;
            case 'updateProfile':
                updateProfileAction({...user}).then(payload=>{
                    console.log(payload)
                    resultHandler({actionType,payload})
                });
                break;
            case 'updateProfileImage':
                console.log(files)
                updateProfileImageAction({files}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'updatePassword':
                updatePasswordAction({oldPassword,newPassword}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'sendPhoneVerifyCode':
                sendPhoneVerifyCodeAction({countryCode, phoneNumber}).then(payload => resultHandler({actionType,payload}));
                break;
            case 'resetPasswordByCode':
                resetPasswordByCodeAction({code,password:newPassword}).then(payload=>resultHandler({actionType,payload}));
                break;
            case 'fastLogin':
                dispatchLocalAction(dispatch,'getLogin')({...data.payload}).then(payload=>resultHandler({actionType,payload}))
                break;
            case 'logout':
                logoutAction().then(window.pushG(authRoute.main.to));
                break;
            case 'resetPasswordByPhoneVerificationCode':
                findPasswordAction({countryCode, phoneNumber, verificationCode}).then(payload=>resultHandler({actionType,payload}))
                break;
            case 'postVerification':
                const files = [];
				Object.entries(data.value).map(entry => {
					if (!_.isEmpty(entry[1])) {
						files.push({ file: entry[1][0].file, tags: `#${data.role}#${entry[0]}#0` });
					}
					return entry;
				});
                postVerificationFilesAction({imageFiles:files,role:data.role}).then(payload=>resultHandler({actionType,payload}))
                break;
            case 'checkHasNewMessage':
                localStorageSafe.getItem('authState', 'object').accessToken && getHasNewMessageAction()
                break;
            case 'postHousingRequest':
                postHousingRequestAction({...data.value}).then(payload=>resultHandler({actionType,payload}))
                break;
            default:
                console.log(data);
                break;
        }
    },[]);
    const _onLoad = useCallback((data={}) => {
        const {loginWechat} = authRoute;
        const {actionType,code} = data;
        if(code){
            switch(actionType){
                case loginWechat.url:
                    loginWechatAction(data);
                    break;
                case 'checkAuthState':
                    const checkAuthState = {...localStorageSafe.getItem('authState','object'),condition:{isLoading:false,error:{},isSuccess:false}};
                    console.log(checkAuthState)
                    if (checkAuthState.accessToken){
                        dispatch({actionType:'getAuth',payload:{...checkAuthState}})
                    }
                    break;
                default:
                    break;
            }
        }
    },[]);
    const genderOptions = useMemo(() => [ { key: 1, text: t('Male'), value: '1' }, { key: 2, text: t('Female'), value: '2' } ], [ t ]);
    console.log(state.condition)
    return <Context.Provider value={{ state,accessToken, onChange:_onChange, onLoad:_onLoad,genderOptions}}>{props.children}</Context.Provider>
}
export const withAuth = Component => props => {
    return(
        <Provider>
            <Component {...props}/>
        </Provider>
    );
};

export default Context;
