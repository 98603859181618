import localStorageSafe from '../../lib/localStorageSafe';

const autharizedHeaders = () => {
	if (localStorage.getItem('testToken')) {
		return { Authorization: `Bearer ` + localStorage.getItem('testToken'), 'Content-Type': 'application/json' };
	} else if(localStorageSafe.getItem('authState', 'object').accessToken){
		return { Authorization: `Bearer ` + localStorageSafe.getItem('authState', 'object').accessToken };
	}else if (localStorage.getItem('accessToken')) {
		return { Authorization: `Bearer ` + localStorage.getItem('accessToken'), 'Content-Type': 'application/json' };
	} else {
		return {
			'Content-Type': 'application/json'
		};
	}
};
export default autharizedHeaders;
