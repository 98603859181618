import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import memeberToUser from './mapping/memberToUser';
import autharizedHeaders from './lib/autharizedHeaders';
import {uploadImages} from './global.api';
import ReactGA from 'react-ga';

const uploadImagesAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API + '/uploadImages' });

export const ProfileToProfileFormData = (data) => {
	const formData = new FormData();
	const fieldObj = _.pickBy(data, _.identity);
	const fieldKeys = _.keys(fieldObj);
	_.values(fieldObj).map((fieldValue, index) => {
		formData.append(fieldKeys[index], fieldValue);
	});
	for (var pair of formData.entries()) {
		console.log(pair[0]+ ', ' + pair[1]); 
	}
	return formData;
};
// User API
const userAPI = axios.create({ baseURL: process.env.REACT_APP_DATA_API });
const userPostAPI = axios.create({baseURL: process.env.REACT_APP_DATA_API,headers:{'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarydOLJlTQL4noM1w4S'}})
export const getProfileAPI = async () => await userAPI.get('/showMyProfile', { headers: autharizedHeaders() }).then(res => ({ user: res.data }));

export const postProfileAPI = async data =>{
	return await userPostAPI.post('/editProfile', ProfileToProfileFormData(data), { headers: autharizedHeaders() }).then(res => {
		return { user: res.data };
	});
}
export const postProfileImageAPI = async ({ files=[{}] }) => {
	return await postProfileAPI({ avatar: files[0] });
};
export const getHasNewMessage = async() => {
	return userAPI.get('/V2/hasNewMessage?hasLandlordMessage=true&hasTenantMessage=true',{headers: autharizedHeaders()}).then(res =>{
		return { ...res.data}
	})
}
// Auth API
const authAPI = axios.create({ baseURL: process.env.REACT_APP_AUTH_API + '/auth', headers: { Authorization: 'Basic bG9uZ3Rlcm1fY2xpZW50OmFjbWVzZWNyZXQ=' } });

export const checkavailabilityAPI = async ({ countryCode, phoneNumber }) =>
	await authAPI.get('/login/isregistered', { params: { countryCode, phoneNumber, service:'lasfu' } }).then(async res => {
		if (res.data.userRegistered) {
			return { auth: { countryCode, phoneNumber, userRegistered: true, passwordSet:res.data.userInfo.passwordSet } };
		} else {
			const secondsToExpire = await sendCodeAPI({ countryCode, phoneNumber });
			return { auth: { countryCode, phoneNumber, userRegistered: false, secondsToExpire } };
		}
	});
export const signUpPhoneAPI = async ({ countryCode, phoneNumber, verificationCode }) =>
	await authAPI.post('/signup/phone', { countryCode, phoneNumber, verificationCode }).then(async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		tokens && (
			ReactGA.event({
				category:'Auth',
				action:'Sign up by phone'
			})
		)
		const user = await userAPI.get('/showMyProfile', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => res.data);
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});

export const sendCodeAPI = async ({ countryCode, phoneNumber }) => await authAPI.post('/sendcode', { countryCode, phoneNumber }).then(res => +res.data.secondsToExpire);

export const loginAPI = async ({ countryCode, phoneNumber, username, password, email,verificationCode }) =>
	await authAPI.post('/login', { countryCode, phoneNumber, username, password, email,verificationCode }).then(async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		tokens && (
			ReactGA.event({
				category:'Auth',
				action:`Sign in by phone with ${verificationCode?'verification code':'password'}`
			})
		)
		const user = await userAPI.get('/showMyProfile', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => res.data);
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});

export const loginWechatAPI = async ({ code }) =>
	await authAPI.post('/login/wechat', { code }).then(async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		tokens && (
			ReactGA.event({
				category:'Auth',
				action:'Sign in by wechat'
			})
		)
		const user = await userAPI.get('/showMyProfile', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => res.data);
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});

export const userinfoPasswordResetRequestByphoneAPI = async ({ countryCode, phoneNumber, verificationCode }) =>
	await authAPI.post('/userinfo/password/reset/request/byphone', { countryCode, phoneNumber, verificationCode }).then(res => {
		return { countryCode, phoneNumber, verificationCode, ...res.data };
	});
export const userinfoBindWechatUrlAPI = async ({ code }) => await authAPI.get('/userinfo/bind/wechat', { code }, { headers: autharizedHeaders(), params: { service: process.env.REACT_APP_URL_NAME } }).then(res => res.data.redirectUrl);
export const userinfoBindWechatAPI = async ({ code }) => 
	await authAPI.put('/userinfo/bind/wechat', { code }, { headers: autharizedHeaders(), params: { service: process.env.REACT_APP_URL_NAME } }).then( async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		const user = await userAPI.get('/showMyProfile', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => res.data);
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});
export const userinfoBindPhoneAPI = async ({ countryCode, phoneNumber, verificationCode }) =>
	await authAPI.put('/userinfo/bind/phone', { countryCode, phoneNumber, verificationCode }, { headers: autharizedHeaders(), params: { service: process.env.REACT_APP_URL_NAME } }).then( async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		const user = await userAPI.get('/showMyProfile', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => res.data);
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});
export const userinfoPasswordChangeAPI = async ({ oldPassword, newPassword }) => await authAPI.put('/userinfo/password/change', { oldPassword, newPassword }, { headers: autharizedHeaders() }).then(res => res.data);
export const userinfoUpdateAPI = async ({ email, password, phoneNumber, countryCode, redirectDomian }) =>
	await authAPI.put('/userinfo/update', { email, password, phoneNumber, countryCode, redirectDomian }, { headers: autharizedHeaders() }).then(res => res.data);
export const userinfoPasswordResetAPI = async ({ code, password }) =>
	await authAPI.post('/userinfo/password/reset', { code, password }).then(async res => {
		const data = res.data || {};
		const { tokens, userRegisterStatus, roles } = data;
		const user = await userAPI.get('/showMyProfile', { headers: { Authorization: `Bearer ` + tokens.accessToken } }).then(res => res.data);
		return { auth: { ...tokens, ...userRegisterStatus, roles }, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken, user };
	});
export const postVerificationFiles = async data => {
	const { imageFiles = [],role } = data;
	const formData = new FormData();
	const uploadParams = {withoutWaterMark:false};
	imageFiles.forEach((fileObj) => {
		formData.append('images', fileObj.file);
        formData.append('tagsList', fileObj.tags);
    });
    const paramObj = _.pickBy(uploadParams,_.identity);
	const paramKeys = _.keys(paramObj)
	_.values(paramObj).map((paramValue,index)=>{
		return formData.append(paramKeys[index],paramValue)
	})
	const uploadedImages = await uploadImagesAPI.post('/', formData, { headers: { ...autharizedHeaders(), 'Content-Type': 'multipart/mixed' } }).then(res => res.data.images.map(image=>({...image,fileUrl:image.imageUrl})));
	return await userAPI.post('/portfolioImagesCreate', {files:uploadedImages,isBelongLandlord:role==='landlord',checkManipulate:'true'},{ headers:autharizedHeaders() });
} 

export const postHousingRequest = async data => {
	return await userAPI.post('/customerServiceContact/housingRequest',{housingRequest:{...data,type:'housingRequest'}}).then(res=>res.data);
}
//Registered action
export const controllerFieldObj = {
	postLogin: { name: 'postLogin', type: 'getAuth', url: '/login', method: 'post' },
	postLoginPhone: { name: 'postLoginPhone', type: 'getAuth', url: '/login', method: 'post' },
	postSignupEmail: { name: 'postSignupEmail', type: 'getAuth', url: '/signup/email', method: 'post' },
	postSignupPhone: { name: 'postSignupPhone', type: 'getAuth', url: '/signup/phone', method: 'post' },
	postSignupCheck: { name: 'postSignupCheck', type: 'postSignupCheck', url: '/signup/checkavailability', method: 'post' },
	getLoginWechatAuthorize: { name: 'getLoginWechatAuthorize', type: 'getLoginWechatAuthorize', url: '/login/wechat/authorize', method: 'get' },
	postLoginWechat: { name: 'postLoginWechat', type: 'getAuth', url: '/login/wechat', method: 'post' },
	getLoginWechat: { name: 'getLoginWechat', type: 'getLoginWechat', url: '/login/wechat', method: 'get' },
	postSendcode: { name: 'postSendcode', type: 'success', url: '/sendcode', method: 'post' },
	getUserinfo: { name: 'getUserinfo', type: 'getUserinfo', url: '/userinfo', method: 'get' },
	putUserinfoPasswordChange: { name: 'putUserinfoPasswordChange', type: 'putUserinfo', url: '/userinfo/password/change', method: 'put' },
	putUserinfoUpdate: { name: 'putUserinfoUpdate', type: 'putUserinfo', url: '/userinfo/update', method: 'put' },
	postUserinfoPasswordResetRequest: { name: 'postUserinfoPasswordResetRequest', type: 'success', url: '/userinfo/password/reset/request', method: 'post' },
	postUserinfoPasswordReset: { name: 'postUserinfoPasswordReset', type: 'authWebhook', url: '/userinfo/password/reset', method: 'post' },
	postUserinfoEmailVerifyRequest: { name: 'postUserinfoEmailVerifyRequest', type: 'postUserinfoEmailVerifyRequest', url: '/userinfo/email/verify/request', method: 'post' },
	postUserinfoEmailVerify: { name: 'postUserinfoEmailVerify', type: 'authWebhook', url: '/userinfo/email/verify', method: 'post' },
	postUserinfoEmailFind: { name: 'postUserinfoEmailFind', type: 'success', url: '/userinfo/email/find', mehod: 'post' },
	putUserinfoBindWechat: { name: 'putUserinfoBindWechat', type: 'authWebhook', url: '/userinfo/bind/wechat', method: 'put' },
	putUserinfoBindPhone: { name: 'putUserinfoBindPhone', type: 'authWebhook', url: '/userinfo/bind/phone', method: 'put' }
};
