import React, { useMemo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import parseQuery from '../lib/parseQuery';
const RouteBasic = ({ component: Component, props: cProps, path, title, name, location, computedMatch, ...rest }) => {
	const render = useMemo(
		() => {
			return (
				<Route
					path={path}
					location={{ ...location, title, name, query: parseQuery.parse(location.search), parseQuery }}
					computedMatch={computedMatch}
					name={name}
					title={title}
					{...rest}
					render={props => {
						return <Component {...props} {...cProps} />;
					}}
				/>
			);
		},
		[ location, title, name ]
	);
	return render;
};

export default RouteBasic;
