const getLanguage = text => {
	if (text !== undefined) {
		return /[\u4e00-\u9fa5]/.test(text) ? 'zh' : 'en';
	}

	let language = localStorage.getItem('language');
	if (!language) {
		language = window.navigator.userLanguage === 'en' ? 'en' : 'zh';
	}
	return language;
};

export default getLanguage;
